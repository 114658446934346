import React from "react";
import theme from "theme";
import { Theme, Link, Text, Box, Section, Button, Image, Span } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";

// eslint-disable-next-line import/no-anonymous-default-export
export default (() => {
	const scrollToSection = (id) => {
		const section = document.getElementById(id);
		if (section) {
			closePanel();
			section.scrollIntoView({ behavior: 'smooth' });
		}
	}

	const closePanel = () => {

	}

	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				JaouadPanorama, LLC - Discover the Panorama of Digital Possibilities.
			</title>
			<meta name={"description"} content={"Discover the Panorama of Digital Possibilities."} />
			<meta property={"og:title"} content={"JaouadPanorama, LLC"} />
			<meta property={"og:description"} content={"Discover the Panorama of Digital Possibilities."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6701e4c69335410018c6e7bc/images/JaouadPanorama,%20LLC%20logo-C_Small.png?v=2024-10-06T01:20:59.792Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6701e4c69335410018c6e7bc/images/JaouadPanorama,%20LLC%20logo-C_Small.png?v=2024-10-06T01:20:59.792Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6701e4c69335410018c6e7bc/images/JaouadPanorama,%20LLC%20logo-C_Small.png?v=2024-10-06T01:20:59.792Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6701e4c69335410018c6e7bc/images/JaouadPanorama,%20LLC%20logo-C_Small.png?v=2024-10-06T01:20:59.792Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6701e4c69335410018c6e7bc/images/JaouadPanorama,%20LLC%20logo-C_Small.png?v=2024-10-06T01:20:59.792Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6701e4c69335410018c6e7bc/images/JaouadPanorama,%20LLC%20logo-C_Small.png?v=2024-10-06T01:20:59.792Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6701e4c69335410018c6e7bc/images/JaouadPanorama,%20LLC%20logo-C_Small.png?v=2024-10-06T01:20:59.792Z"} />
		</Helmet>
		<Section padding="38px 0 38px 0" quarkly-title="Header">
			<Override slot="SectionContent" flex-direction="row" display="block"/>
			<Link
				text-decoration-line="initial"
				hover-opacity=".8"
				width="20%"
				md-width="40%"
				sm-width="50%"
				href="/"
				display="flex"
			>
				<Text
					font="normal 700 14px/1.5 --fontFamily-googleFiraSans"
					color="#1064C7"
					transform="rotateX(0deg)"
					margin="0px 0px 0px 0px"
					sm-background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6701e4c69335410018c6e7bc/images/JaouadPanorama,%20LLC%20logo-C_Small.png?v=2024-10-06T01:20:59.792Z) 0% 0% /contain repeat scroll padding-box"
					quarkly-title="Logo"
					sm-width="50px"
					sm-height="50px"
					sm-display="block"
					sm-flex="1 1 0%"
					sm-overflow-x="visible"
					sm-position="static"
					sm-transform="rotateX(0deg)"
					width="50px"
					height="50px"
					background="url(https://uploads.quarkly.io/6701e4c69335410018c6e7bc/images/JaouadPanorama,%20LLC%20logo-C_Small.png?v=2024-10-06T01:20:59.792Z) 0% 0%/100% scroll"
				/>
				<Text
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--dark"
					sm-text-align="center"
					sm-padding="9px 0px 0px 0px"
					style={{alignContent: 'center'}}
				>
					JaouadPanorama
				</Text>
			</Link>
			<Box md-justify-content="flex-end" style={{alignContent: 'center'}}>
				<Override slot="Children" display="flex" />
				<Override slot="Content" padding="0px 0px 0px 0px" md-margin="38px 0px 0px 0px" />
				<Override
					slot="Button Text"
					md-font="--lead"
					md-text-transform="uppercase"
					sm-margin="3px 6.3px 0px 0px"
					md-margin="1px 6.3px 0px 0px"
				/>
				<Box
					justify-content="flex-end"
					md-align-items="center"
					md-flex-direction="row"
					md-justify-content="center"
					md-padding="20px 0px 20px 0px"
					md-margin="0px 0px 0px 0px"
					display="flex"
				>
					<Link
						href="#solutions"
						onClick={() => scrollToSection('solutions')}
						text-decoration-line="initial"
						color="--dark"
						font="--base"
						padding="5px 10px 5px 10px"
						margin="0px 15px 0px 15px"
						transition="opacity 0.3s ease-in-out 0s"
						md-margin="0 0px 8px 0px"
						md-font="normal 400 18px/1.5 &quot;Fira Sans&quot;, sans-serif"
						hover-opacity=".8"
					>
						Solutions
					</Link>
					<Link
						md-margin="0 0px 8px 0px"
						href="#products"
						key="prod1"
						text-decoration-line="initial"
						color="--dark"
						font="--base"
						margin="0px 15px 0px 15px"
						padding="5px 10px 5px 10px"
						transition="opacity 0.3s ease-in-out 0s"
						hover-opacity=".8"
						md-font="normal 400 18px/1.5 &quot;Fira Sans&quot;, sans-serif"
					>
						Products
					</Link>
					<Link
						md-font="normal 400 18px/1.5 &quot;Fira Sans&quot;, sans-serif"
						href="#pricing"
						key="princing1"
						text-decoration-line="initial"
						color="--dark"
						font="--base"
						padding="5px 10px 5px 10px"
						margin="0px 15px 0px 15px"
						transition="opacity 0.3s ease-in-out 0s"
						hover-opacity=".8"
						md-margin="0 0px 8px 0px"
					>
						Pricing
					</Link>
					<Link
						color="--dark"
						margin="0px 0px 0px 15px"
						href="#testimonial"
						key="testimonial1"
						font="--base"
						padding="5px 10px 5px 10px"
						transition="opacity 0.3s ease-in-out 0s"
						hover-opacity=".8"
						md-margin="0 0px 8px 0px"
						md-font="normal 400 18px/1.5 &quot;Fira Sans&quot;, sans-serif"
						text-decoration-line="initial"
					>
						Testimonial
					</Link>
				</Box>
			</Box>
		</Section>
		<Section background="--color-darkL1" padding="0px 0 0px 0" quarkly-title="HeroBlock" md-flex-wrap="wrap">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				width="50%"
				padding="80px 0px 40px 0px"
				md-padding="40px 0px 40px 0px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				lg-padding="80px 32px 40px 0px"
				md-width="100%"
				quarkly-title="Hero Box Left"
			>
				<Box
					lg-width="100%"
					display="flex"
					flex-direction="column"
					width="70%"
					justify-content="space-between"
					flex="1 1 0%"
					quarkly-title="Hero Text"
				>
					<Text
						font="--headline3"
						color="--light"
						sm-font="400 36px/1.2 &quot;Fira Sans&quot;, sans-serif"
						margin="0px 0px 0px 0px"
						quarkly-title="Title"
					>
						Unlock Your Business Potential with Expert IT Solutions
					</Text>
					<Text
						margin="32px 0px 0px 0px"
						font="--lead"
						color="--light"
						md-padding="0px 0px 40px 0px"
						quarkly-title="Subtitle"
					>
						Get a tailored strategy to streamline your operations, reduce costs, and scale your business.
					</Text>
					<Link href="https://jaouadpanorama.setmore.com/zakaria" target="_blank">
						<Button
							type="submit"
							background="#3968b8"
							height="51px"
							border-radius="10px"
							disabled={false}
						>
							Book A Consultation
						</Button>
					</Link>
				</Box>
			</Box>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				quarkly-title="Hero Box Right IMG"
			>
				<Image
					src="https://uploads.quarkly.io/6701e4c69335410018c6e7bc/images/hero.svg?v=2024-10-08T23:10:01.497Z"
					max-width="100%"
					min-height="600px"
					object-fit="cover"
					lg-min-height="500px"
					sm-min-height="400px"
				/>
			</Box>
		</Section>
		<Section padding="104px 0 104px 0" lg-padding="40px 0 40px 0" quarkly-title="Why Us">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				lg-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Text
					font="normal 500 60px/1.2 --fontFamily-googleFiraSans"
					color="--dark"
					md-font="normal 500 40px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					lg-margin="0px 0px 32px 0px"
					margin="0px 0px 0px 0px"
				>
					Why Choose Us?
				</Text>
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				width="50%"
				lg-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				display="flex"
				flex-wrap="wrap"
			>
				<Text margin="0px 0px 0px 0px" font="--lead" color="--dark" sm-font="normal 400 16px/1.5 &quot;Fira Sans&quot;, sans-serif">
					Our custom software development platform provides all the tools businesses need for digital transformation. Manage your web development, CRM, and integrations in a single solution. Our platform is packed with so many features that it’s impossible to list them all! Some of the key benefits can be found below.
				</Text>
				<Box width="33.333%" margin="32px 0px 0px 0px" padding="0px 30px 0px 0px" md-width="100%">
					<Text color="--dark" margin="0px 0px 16px 0px" font="normal 500 18px/1.5 --fontFamily-googleFiraSans">
						Discovery
					</Text>
					<Text margin="0px 0px 8px 0px" font="--base" color="--dark">
						Business Needs Analysis
					</Text>
					<Text font="--base" color="--dark" margin="0px 0px 8px 0px">
						Process Mapping
					</Text>
					<Text margin="0px 0px 8px 0px" font="--base" color="--dark">
						Technology Assessment
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--dark">
						Goal Alignment
					</Text>
				</Box>
				<Box padding="0px 30px 0px 0px" md-width="100%" width="33.333%" margin="32px 0px 0px 0px">
					<Text margin="0px 0px 16px 0px" font="normal 500 18px/1.5 --fontFamily-googleFiraSans" color="--dark">
						Dev & Integration
					</Text>
					<Text margin="0px 0px 8px 0px" font="--base" color="--dark">
						Full-Stack Web Dev
					</Text>
					<Text margin="0px 0px 8px 0px" font="--base" color="--dark">
						CRM & API Integrations
					</Text>
					<Text margin="0px 0px 8px 0px" font="--base" color="--dark">
						Cloud Infrastructure
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--dark">
						Custom Software Solutions
					</Text>
				</Box>
				<Box width="33.333%" margin="32px 0px 0px 0px" md-width="100%">
					<Text margin="0px 0px 16px 0px" font="normal 500 18px/1.5 --fontFamily-googleFiraSans" color="--dark">
						Support & Scaling
					</Text>
					<Text color="--dark" margin="0px 0px 8px 0px" font="--base">
						Performance Monitoring
					</Text>
					<Text color="--dark" margin="0px 0px 8px 0px" font="--base">
						Continuous Improvements
					</Text>
					<Text margin="0px 0px 8px 0px" font="--base" color="--dark">
						Security & Compliance Updates
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--dark">
						Scalability Planning
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="0px 0 0px 0" quarkly-title="All in one solution" id="solutions">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				width="50%"
				align-items="center"
				border-style="solid"
				padding="80px 0px 80px 0px"
				md-padding="60px 0px 60px 0px"
				empty-border-width="1px"
				border-color="#C4C4C4"
				empty-border-style="solid"
				empty-border-color="LightGray"
				border-width="1px 0px 0px 0px"
				empty-min-height="64px"
				display="flex"
				md-width="100%"
			>
				<Text margin="0px 0px 0px 0px" font="--headline2" color="--dark" lg-font="400 30px/1.2 &quot;Fira Sans&quot;, sans-serif">
					<Span
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						lg-font="700 30px/48px &quot;Fira Sans&quot;, sans-serif"
						color="--primary"
						word-break="normal"
						white-space="normal"
						font="700 40px/48px &quot;Fira Sans&quot;, sans-serif"
						overflow-wrap="normal"
						user-select="auto"
						pointer-events="auto"
					>
						b2b
					</Span>
					{" "}All in one Solution
				</Text>
			</Box>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
			>
				<Text
					margin="0px 0px 0px 0px"
					font="--headline4"
					color="--light"
					lg-font="normal 400 20px/1.3 &quot;Fira Sans&quot;, sans-serif"
					sm-font="normal 400 18px/1.4 &quot;Fira Sans&quot;, sans-serif"
					background="--color-primary"
					padding="32px 24px 32px 24px"
				>
					At JaouadPanorama, we blend cutting-edge technology with a deep understanding of your business to deliver scalable solutions. Whether it's automating processes, integrating systems, or building software from the ground up, we ensure a seamless, future-proof solution.
				</Text>
			</Box>
		</Section>
		<Section padding="0px 0 0px 0" quarkly-title="Main Services">
			<Override slot="SectionContent" md-flex-wrap="wrap" flex-direction="row" />
			<Box
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				display="flex"
			>
				<Image
					max-width="100%"
					min-height="632px"
					object-fit="cover"
					sm-min-height="16px"
					src="https://images.unsplash.com/photo-1603539947678-cd3954ed515d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80"
					srcSet="https://images.unsplash.com/photo-1603539947678-cd3954ed515d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80 500w,https://images.unsplash.com/photo-1603539947678-cd3954ed515d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80 800w,https://images.unsplash.com/photo-1603539947678-cd3954ed515d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1080&q=80 1080w,https://images.unsplash.com/photo-1603539947678-cd3954ed515d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1600&q=80 1600w,https://images.unsplash.com/photo-1603539947678-cd3954ed515d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80 2000w,https://images.unsplash.com/photo-1603539947678-cd3954ed515d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2600&q=80 2600w,https://images.unsplash.com/photo-1603539947678-cd3954ed515d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3200&q=80 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-color="LightGray"
				width="50%"
				empty-min-width="64px"
				empty-border-style="solid"
				display="flex"
				flex-direction="column"
				md-width="100%"
				empty-min-height="64px"
				quarkly-title="Right Box"
			>
				<Box
					empty-border-width="1px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					padding="32px 32px 32px 32px"
					height="50%"
					empty-min-width="64px"
					empty-min-height="64px"
				>
					<Text
						width="60%"
						lg-width="100%"
						lg-font="normal 400 24px/1.3 &quot;Fira Sans&quot;, sans-serif"
						margin="0px 0px 16px 0px"
						font="--headline4"
						color="--dark"
					>
						Custom CRM Solution
					</Text>
					<Text
						margin="0px 0px 65px 0px"
						font="--base"
						color="--dark"
						width="70%"
						lg-width="100%"
					>
						Simplify customer management with intuitive, responsive CRM systems that work seamlessly across devices. From lead tracking to data integration, our platforms make managing your customers easy.
					</Text>
					<Link
						font="--lead"
						href="#"
						text-decoration-line="initial"
						color="--primary"
						padding="5px 35px 5px 0px"
						background="url(https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/keyboard_arrow_right_blue.svg?v=2021-09-16T22:45:08.539Z) 100% -5px/contain no-repeat"
					>
						Learn more
					</Link>
				</Box>
				<Box
					empty-border-style="solid"
					empty-border-color="LightGray"
					padding="32px 32px 32px 32px"
					background="--color-lightD1"
					height="50%"
					empty-min-width="64px"
					empty-min-height="64px"
					empty-border-width="1px"
				>
					<Text
						margin="0px 0px 16px 0px"
						font="--headline4"
						color="--dark"
						width="80%"
						lg-width="100%"
						lg-font="normal 400 24px/1.3 &quot;Fira Sans&quot;, sans-serif"
					>
						API & Cloud Integration
					</Text>
					<Text
						margin="0px 0px 65px 0px"
						font="--base"
						color="--dark"
						width="70%"
						lg-width="100%"
					>
						Connect your business applications and data through powerful API and cloud integrations. Boost efficiency, improve communication, and enable scalable growth with our custom integration services.
					</Text>
					<Link
						href="#"
						text-decoration-line="initial"
						color="--primary"
						padding="5px 35px 5px 0px"
						background="url(https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/keyboard_arrow_right_blue.svg?v=2021-09-16T22:45:08.539Z) 100% -5px/contain no-repeat"
						font="--lead"
					>
						Learn more
					</Link>
				</Box>
			</Box>
		</Section>
		<Section padding="64px 0 0px 0" quarkly-title="products" id="products">
			<Override
				slot="SectionContent"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0px"
				lg-grid-template-columns="1fr"
			/>
			<Box
				empty-border-color="LightGray"
				grid-column="1 / span 3"
				padding="16px 32px 60px 16px"
				lg-grid-column="auto / auto"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text color="--dark" lg-font="400 30px/1.2 &quot;Fira Sans&quot;, sans-serif" margin="0px 0px 16px 0px" font="--headline2">
					On-site & Remote{" "}
					<Span
						color="--primary"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-overflow="clip"
						font="700 50px/60px &quot;Fira Sans&quot;, sans-serif"
						lg-font="400 30px/1.2 &quot;Fira Sans&quot;, sans-serif"
						text-indent="0"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Business
					</Span>
					{" "}Solutions
				</Text>
				<Text
					lg-margin="0px 0px 16px 0px"
					margin="0px 0px 32px 0px"
					font="--lead"
					color="--dark"
					width="60%"
					lg-width="100%"
					sm-font="normal 400 16px/1.5 &quot;Fira Sans&quot;, sans-serif"
				>
					Manage both physical and virtual business operations with streamlined tools for automation, customer management, and process integration.
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				border-style="solid"
				lg-border-style="none"
				lg-border-width="0px"
				empty-border-style="solid"
				empty-min-height="64px"
				empty-border-width="1px"
				padding="16px 32px 60px 16px"
				border-width="1px 1px 1px 0px"
				border-color="--color-grey"
				empty-min-width="64px"
			>
				<Box>
					<Text margin="15px 0px 16px 0px" font="--headline4" color="--dark">
						AI-Powered Automation
					</Text>
					<Text
						sm-font="normal 400 16px/1.5 &quot;Fira Sans&quot;, sans-serif"
						lg-margin="0px 0px 45px 0px"
						margin="0px 0px 0px 0px"
						font="--lead"
						color="--dark"
						width="100%"
					>
						Leverage AI to automate workflows, streamline operations, and optimize business performance.
					</Text>
				</Box>
				<Link
					href="#"
					text-decoration-line="initial"
					color="--primary"
					padding="5px 35px 5px 0px"
					background="url(https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/keyboard_arrow_right_blue.svg?v=2021-09-16T22:45:08.539Z) 100% -5px/contain no-repeat"
					font="--lead"
				>
					Learn more
				</Link>
			</Box>
			<Box
				border-color="--color-grey"
				lg-border-style="none"
				lg-border-width="0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				padding="16px 32px 60px 16px"
				border-style="solid"
				empty-border-width="1px"
				empty-border-style="solid"
				border-width="1px 1px 1px 0px"
			>
				<Box>
					<Text margin="15px 0px 16px 0px" font="--headline4" color="--dark">
						Cloud Infrastructure
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="--lead"
						color="--dark"
						width="100%"
						sm-font="normal 400 16px/1.5 &quot;Fira Sans&quot;, sans-serif"
						lg-margin="0px 0px 45px 0px"
					>
						Ensure seamless business operations with scalable cloud solutions that improve security and connectivity.
					</Text>
				</Box>
				<Link
					text-decoration-line="initial"
					color="--primary"
					padding="5px 35px 5px 0px"
					background="url(https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/keyboard_arrow_right_blue.svg?v=2021-09-16T22:45:08.539Z) 100% -5px/contain no-repeat"
					font="--lead"
					href="#"
				>
					Learn more
				</Link>
			</Box>
			<Box
				padding="16px 32px 60px 16px"
				border-width="1px 0px 1px 0px"
				border-style="solid"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				border-color="--color-grey"
				lg-border-width="0px"
				lg-border-style="none"
			>
				<Box>
					<Text margin="15px 0px 16px 0px" font="--headline4" color="--dark">
						Inclusive Digital Solutions
					</Text>
					<Text
						color="--dark"
						width="100%"
						sm-font="normal 400 16px/1.5 &quot;Fira Sans&quot;, sans-serif"
						sm-margin="0px 0px 4px 0px"
						lg-margin="0px 0px 45px 0px"
						margin="0px 0px 0px 0px"
						font="--lead"
					>
						Create customized platforms that accommodate diverse business needs, fostering inclusivity and growth.
					</Text>
				</Box>
				<Link
					text-decoration-line="initial"
					color="--primary"
					padding="5px 35px 5px 0px"
					background="url(https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/keyboard_arrow_right_blue.svg?v=2021-09-16T22:45:08.539Z) 100% -5px/contain no-repeat"
					font="--lead"
					href="#"
				>
					Learn more
				</Link>
			</Box>
		</Section>
		<Section padding="0px 0 0px 0" quarkly-title="Benefits" id="benefits">
			<Components.Tabs
				width="100%"
				md-flex-wrap="wrap"
				margin="0px 0px -4px 0px"
				defaultTab="one"
				display="flex"
				flex-direction="row-reverse"
			>
				<Override slot="Tab :active" opacity="1" md-color="--dark" transform="translateX(0px)" />
				<Override slot="Tab two">
					User-Friendly
				</Override>
				<Override slot="Tab three">
					Customizable
				</Override>
				<Override slot="Tab four" margin="0px 0px 20px 0px">
					Effective
				</Override>
				<Override slot="Tab one">
					Cost-Effectif
				</Override>
				<Override
					slot="Tablist"
					flex-direction="column"
					md-width="100%"
					md-position="static"
					md-align-items="flex-start"
					md-order="1"
					md-flex-direction="row"
					width="25%"
					padding="16px 16px 16px 24px"
					lg-width="40%"
					md-padding="0px 0px 0px 0px"
					md-flex-wrap="wrap"
				/>
				<Override slot="TabPanels" width="75%" lg-width="60%" md-width="100%" />
				<Override
					slot="Tab"
					padding="16px 6px 16px 6px"
					transition="transform 1s ease 0s"
					color="--dark"
					sm-width="50%"
					sm-text-align="left"
					md-width="50%"
					sm-font="normal 400 20px/1.3 &quot;Fira Sans&quot;, sans-serif"
					md-color="--grey"
					text-align="left"
					background="rgba(32, 108, 236, 0)"
					margin="0px 0px 40px 0px"
					md-opacity="1"
					md-font="normal 400 16px/1.3 &quot;Fira Sans&quot;, sans-serif"
					font="--headline4"
					transform="translateX(0px)"
					opacity="0.5"
					md-margin="0px 0px 8px 0px"
					type="submit"
				>
					Affordable
				</Override>
				<Components.Tab tabId="one">
					<Override slot="Tab two">
						Animation
					</Override>
					<Image
						lg-height="460px"
						padding="0px 0px 0px 0px"
						src="https://images.unsplash.com/photo-1590402494587-44b71d7772f6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80"
						max-width="100%"
						height="560px"
						object-fit="cover"
						width="100%"
						quarkly-title="Cost Effectif"
						srcSet="https://images.unsplash.com/photo-1590402494587-44b71d7772f6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80 500w,https://images.unsplash.com/photo-1590402494587-44b71d7772f6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80 800w,https://images.unsplash.com/photo-1590402494587-44b71d7772f6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1080&q=80 1080w,https://images.unsplash.com/photo-1590402494587-44b71d7772f6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1600&q=80 1600w,https://images.unsplash.com/photo-1590402494587-44b71d7772f6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80 2000w,https://images.unsplash.com/photo-1590402494587-44b71d7772f6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2600&q=80 2600w,https://images.unsplash.com/photo-1590402494587-44b71d7772f6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3200&q=80 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Components.Tab>
				<Components.Tab tabId="two">
					<Image
						max-width="100%"
						height="560px"
						object-fit="cover"
						width="100%"
						lg-height="460px"
						src="https://images.unsplash.com/photo-1508921340878-ba53e1f016ec?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						quarkly-title="User friendly"
						srcSet="https://images.unsplash.com/photo-1508921340878-ba53e1f016ec?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1508921340878-ba53e1f016ec?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1508921340878-ba53e1f016ec?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1508921340878-ba53e1f016ec?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1508921340878-ba53e1f016ec?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1508921340878-ba53e1f016ec?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1508921340878-ba53e1f016ec?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Components.Tab>
				<Components.Tab tabId="three">
					<Image
						width="100%"
						lg-height="460px"
						src="https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						max-width="100%"
						height="560px"
						object-fit="cover"
						quarkly-title="Customizable"
						srcSet="https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Components.Tab>
				<Components.Tab tabId="four">
					<Image
						src="https://images.unsplash.com/photo-1556155092-490a1ba16284?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						height="560px"
						object-fit="cover"
						width="100%"
						lg-height="460px"
						quarkly-title="Effective"
						srcSet="https://images.unsplash.com/photo-1556155092-490a1ba16284?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1556155092-490a1ba16284?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1556155092-490a1ba16284?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1556155092-490a1ba16284?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1556155092-490a1ba16284?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1556155092-490a1ba16284?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1556155092-490a1ba16284?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Components.Tab>
			</Components.Tabs>
		</Section>
		<Section padding="0px 0 0px 0" quarkly-title="Pricing Header" id="pricing">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				align-items="center"
				lg-width="60%"
				md-width="100%"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="75%"
				display="grid"
				overflow-x="hidden"
			>
				<Text
					color="--dark"
					lg-font="400 30px/1.2 &quot;Fira Sans&quot;, sans-serif"
					md-padding="32px 0px 32px 0px"
					margin="0px 0px 0px 0px"
					font="--headline2"
				>
					Custom Software
					<br />
					Solutions{" "}
					<Span
						color="--primary"
						font="700 50px/60px &quot;Fira Sans&quot;, sans-serif"
						lg-font="400 30px/1.2 &quot;Fira Sans&quot;, sans-serif"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Pricing
					</Span>
				</Text>
				<Link href="https://jaouadpanorama.setmore.com/zakaria" target="_blank">
					<Button
						type="button"
						background="#3968b8"
						// href="https://jaouadpanorama.setmore.com/zakaria"
						// target="_blank"
						height="51px"
						border-radius="10px"
						color="#ffffff"
						text-transform="uppercase"
						display="inline-block"
						overflow-x="hidden"
						overflow-y="hidden"
						width="403.2px"
						textDecoration="none"
					>
						Book A Consultation
					</Button>
				</Link>
			</Box>
			<Box
				empty-border-color="LightGray"
				padding="32px 32px 79px 32px"
				md-padding="32px 32px 32px 32px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				lg-width="40%"
				md-width="100%"
				sm-padding="32px 32px 32px 32px"
				empty-border-style="solid"
				width="25%"
				background="--color-primary"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline4"
					color="--light"
					width="100%"
					lg-font="normal 400 24px/1.3 &quot;Fira Sans&quot;, sans-serif"
				>
					Bring Your Business Together
				</Text>
				<Text color="--light" width="100%" margin="0px 0px 16px 0px" font="--base">
					No long-term commitments, setup fees, or hidden costs—just flexible, transparent pricing that adapts as your business grows.{"\n\n"}
				</Text>
			</Box>
		</Section>
		<Section padding="0px 0 0px 0" display="none" quarkly-title="Testimonials Pic">
			<Override slot="SectionContent" flex-direction="row" lg-flex-wrap="wrap" />
			<Box
				padding="16px 32px 65px 32px"
				border-style="solid"
				border-color="--color-grey"
				empty-min-height="64px"
				empty-border-width="1px"
				width="50%"
				background="--color-lightD1"
				border-width="1px 1px 1px 0px"
				lg-width="100%"
				lg-border-width="0px"
				empty-min-width="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
			>
				<Box margin="0px 0px 54px 0px">
					<Text margin="15px 0px 16px 0px" font="--headline3" color="--dark" lg-font="400 30px/1.2 &quot;Fira Sans&quot;, sans-serif">
						Standard
					</Text>
					<Text
						margin="0px 0px 8px 0px"
						font="--lead"
						color="--dark"
						width="100%"
						sm-font="normal 400 16px/1.5 &quot;Fira Sans&quot;, sans-serif"
					>
						128gb SSD storage
					</Text>
					<Text
						color="--dark"
						width="100%"
						sm-font="normal 400 16px/1.5 &quot;Fira Sans&quot;, sans-serif"
						margin="0px 0px 8px 0px"
						font="--lead"
					>
						Full UI kit download
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="--lead"
						color="--dark"
						width="100%"
						sm-font="normal 400 16px/1.5 &quot;Fira Sans&quot;, sans-serif"
					>
						Public API
					</Text>
				</Box>
				<Link
					href="#"
					text-decoration-line="initial"
					color="--primary"
					padding="5px 35px 5px 0px"
					background="url(https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/keyboard_arrow_right_blue.svg?v=2021-09-16T22:45:08.539Z) 100% -5px/contain no-repeat"
					font="--lead"
				>
					From $29
				</Link>
			</Box>
			<Box
				lg-border-width="0px"
				empty-border-width="1px"
				empty-border-color="LightGray"
				width="50%"
				padding="16px 32px 65px 32px"
				border-color="--color-grey"
				border-style="solid"
				lg-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-style="solid"
				position="relative"
				border-width="1px 0px 1px 0px"
			>
				<Image
					bottom="18px"
					left="auto"
					top="auto"
					lg-right="0px"
					sm-bottom="-40px"
					height="156px"
					position="absolute"
					right="88px"
					md-bottom="0px"
					sm-right="-40px"
					width="200px"
					src="https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/Vectary%20texture.png?v=2021-09-18T00:09:23.228Z"
					srcSet="https://smartuploads.quarkly.io/6143a05129a0a1001e6c835c/images/Vectary%20texture.png?v=2021-09-18T00%3A09%3A23.228Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6143a05129a0a1001e6c835c/images/Vectary%20texture.png?v=2021-09-18T00%3A09%3A23.228Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6143a05129a0a1001e6c835c/images/Vectary%20texture.png?v=2021-09-18T00%3A09%3A23.228Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6143a05129a0a1001e6c835c/images/Vectary%20texture.png?v=2021-09-18T00%3A09%3A23.228Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6143a05129a0a1001e6c835c/images/Vectary%20texture.png?v=2021-09-18T00%3A09%3A23.228Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6143a05129a0a1001e6c835c/images/Vectary%20texture.png?v=2021-09-18T00%3A09%3A23.228Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6143a05129a0a1001e6c835c/images/Vectary%20texture.png?v=2021-09-18T00%3A09%3A23.228Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Box
					sm-flex-direction="column"
					margin="0px 0px 54px 0px"
					display="flex"
					width="100%"
					flex-wrap="wrap"
				>
					<Text
						width="100%"
						lg-font="400 30px/1.2 &quot;Fira Sans&quot;, sans-serif"
						margin="15px 0px 16px 0px"
						font="--headline3"
						color="--dark"
					>
						Advanced
					</Text>
					<Box width="50%" sm-width="100%">
						<Text
							margin="0px 0px 8px 0px"
							font="--lead"
							color="--dark"
							width="100%"
							sm-font="normal 400 16px/1.5 &quot;Fira Sans&quot;, sans-serif"
						>
							256gb SSD storage
						</Text>
						<Text
							font="--lead"
							color="--dark"
							width="100%"
							sm-font="normal 400 16px/1.5 &quot;Fira Sans&quot;, sans-serif"
							margin="0px 0px 8px 0px"
						>
							Full UI kit download
						</Text>
						<Text
							sm-font="normal 400 16px/1.5 &quot;Fira Sans&quot;, sans-serif"
							margin="0px 0px 8px 0px"
							font="--lead"
							color="--dark"
							width="100%"
						>
							Public API
						</Text>
					</Box>
					<Box width="50%" sm-width="100%">
						<Text
							margin="0px 0px 8px 0px"
							font="--lead"
							color="--dark"
							width="100%"
							sm-font="normal 400 16px/1.5 &quot;Fira Sans&quot;, sans-serif"
						>
							Full UI pack download
						</Text>
						<Text
							margin="0px 0px 8px 0px"
							font="--lead"
							color="--dark"
							width="100%"
							sm-font="normal 400 16px/1.5 &quot;Fira Sans&quot;, sans-serif"
						>
							24/7 support
						</Text>
					</Box>
				</Box>
				<Link
					color="--primary"
					padding="5px 35px 5px 0px"
					background="url(https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/keyboard_arrow_right_blue.svg?v=2021-09-16T22:45:08.539Z) 100% -5px/contain no-repeat"
					font="--lead"
					href="#"
					text-decoration-line="initial"
				>
					From $39
				</Link>
			</Box>
		</Section>
		<Section padding="0px 0 0px 0" quarkly-title="Testimonials Text" id="testimonial">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-height="64px"
				sm-align-items="center"
				sm-justify-content="center"
				empty-border-width="1px"
				background="--color-dark"
				md-width="100%"
				sm-display="flex"
				sm-padding="32px 16px 32px 16px"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-border-color="LightGray"
				padding="64px 32px 257px 0px"
				md-padding="64px 32px 64px 32px"
			>
				<Box display="flex" flex-direction="column" width="80%" lg-width="100%">
					<Text
						color="--light"
						sm-font="400 36px/1.2 &quot;Fira Sans&quot;, sans-serif"
						lg-text-align="center"
						lg-margin="0px 0px 0px 0px"
						margin="0px 0px 0px 32px"
						font="--headline3"
					>
						Testimonials
					</Text>
					<Box background="--color-darkL2" padding="32px 35px 32px 32px" margin="32px 0px 0px 0px">
						<Text color="--light" margin="0px 0px 0px 0px" lg-text-align="center" font="--lead">
							We appreciated the flexibility of JaouadPanorama’s software in adapting to our growing needs. The ability to create custom solutions like automated workflows and personalized integrations made a big difference for our business.{"\n\n"}
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				empty-border-style="solid"
				background="rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1543269665-7821011040ee?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=774&q=80) 0% 40% /cover repeat scroll padding-box"
				lg-padding="64px 0px 0px 32px"
				md-width="100%"
				md-padding="64px 0px 274px 32px"
				empty-min-height="64px"
				empty-border-width="1px"
				width="50%"
				padding="64px 32px 0px 32px"
				empty-min-width="64px"
				empty-border-color="LightGray"
			>
				<Link
					href="#"
					text-decoration-line="initial"
					color="--light"
					padding="5px 85px 5px 0px"
					background="url(https://uploads.quarkly.io/6143a05129a0a1001e6c835c/images/keyboard_arrow_right.svg?v=2021-09-16T22:08:39.193Z) 100% -12px/51px no-repeat"
					font="--headline3"
					sm-padding="5px 55px 5px 0px"
					sm-font="400 30px/1.2 &quot;Fira Sans&quot;, sans-serif"
				>
					Our Clients Say...
				</Link>
			</Box>
		</Section>
		<Section padding="0px 0 0px 0" quarkly-title="Partners">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				padding="80px 32px 80px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-color="LightGray"
				width="75%"
				display="flex"
				align-items="center"
				lg-width="55%"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				md-padding="32px 0px 32px 0px"
			>
				<Text
					width="80%"
					lg-width="100%"
					margin="0px 0px 0px 0px"
					font="--headline2"
					color="--dark"
					lg-font="400 30px/1.2 &quot;Fira Sans&quot;, sans-serif"
				>
					Empower Your{" "}
					<Span
						lg-font="400 30px/1.2 &quot;Fira Sans&quot;, sans-serif"
						font="700 50px/60px &quot;Fira Sans&quot;, sans-serif"
						color="--primary"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Business
					</Span>
					{" "}with innovative and tailor-made solutions!
				</Text>
			</Box>
			<Box
				empty-min-height="64px"
				padding="42px 32px 42px 32px"
				md-width="100%"
				flex-direction="column"
				md-border-width="0px"
				md-border-style="none"
				empty-min-width="64px"
				display="flex"
				empty-border-style="solid"
				border-color="--color-grey"
				border-style="solid"
				justify-content="center"
				sm-padding="16px 32px 0px 32px"
				border-width="0px 0px 0px 1px"
				empty-border-color="LightGray"
				width="25%"
				lg-width="45%"
				empty-border-width="1px"
			>
				<Text
					md-text-align="left"
					margin="0px 0px 16px 0px"
					font="--headline4"
					color="--dark"
					width="100%"
					text-align="center"
					md-margin="0px 0px 32px 0px"
				>
					Our Partners
				</Text>
				<Components.QuarklycommunityKitCarousel
					autoPlay
					autoPlayIntervalProp="5s"
					showHead={false}
					showText={false}
					showLink={false}
					showArrows={false}
					showDots={false}
					slidesWrapper="box"
					slidesProp="4"
					aspectRatio="16:9"
					overflow-y="hidden"
					height="150px"
				>
					<Override slot="Slide Image" src="https://uploads.quarkly.io/6701e4c69335410018c6e7bc/images/stripe%20logo.png?v=2024-10-09T22:01:50.174Z" />
					<Override slot="Slide Image 4" src="https://uploads.quarkly.io/6701e4c69335410018c6e7bc/images/stripe%20logo.png?v=2024-10-09T22:01:50.174Z" />
					<Override slot="Slide Image 3" src="https://uploads.quarkly.io/6701e4c69335410018c6e7bc/images/openAI.jpg?v=2024-10-09T22:00:36.017Z" />
					<Override slot="Slide Image 2" src="https://uploads.quarkly.io/6701e4c69335410018c6e7bc/images/google%20cloud%20logo.png?v=2024-10-09T21:59:30.276Z" />
					<Override slot="Slide Image 1" src="https://uploads.quarkly.io/6701e4c69335410018c6e7bc/images/aws%20logo%20small.png?v=2024-10-09T21:57:29.064Z" />
				</Components.QuarklycommunityKitCarousel>
			</Box>
		</Section>
		<Section padding="0px 0 48px 0" quarkly-title="Footer">
			<Override slot="SectionContent" flex-direction="row" lg-flex-wrap="wrap" display="block" />
			<Box
				flex-direction="column"
				align-items="flex-start"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				padding="32px 32px 32px 32px"
				display="flex"
				lg-width="100%"
				empty-border-style="solid"
				empty-border-color="LightGray"
				background="--color-primary"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline4"
					color="--light"
					lg-font="normal 400 20px/1.3 &quot;Fira Sans&quot;, sans-serif"
					sm-font="normal 400 18px/1.4 &quot;Fira Sans&quot;, sans-serif"
				>
					Contact info
				</Text>
				<Text
					sm-font="normal 400 18px/1.4 &quot;Fira Sans&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--lead"
					color="--light"
					lg-font="normal 400 20px/1.3 &quot;Fira Sans&quot;, sans-serif"
					quarkly-title="Address"
				>
					624 Washington St, APT A-119, Coventry, RI 02816, USA
				</Text>
				<Link
					href="tel:12345678"
					text-decoration-line="initial"
					color="--light"
					font="--lead"
					margin="0px 0px 16px 0px"
				>
					+1 (401) 654-9934
				</Link>
				<Link
					href="mailto:info@b2bpltfrm.com"
					text-decoration-line="initial"
					color="--light"
					font="--lead"
					margin="0px 0px 32px 0px"
				>
					jaouadpanorama@gmail.com
				</Link>
				<Text
					font="--headline4"
					color="--light"
					lg-font="normal 400 20px/1.3 &quot;Fira Sans&quot;, sans-serif"
					sm-font="normal 400 18px/1.4 &quot;Fira Sans&quot;, sans-serif"
					margin="0px 0px 16px 0px"
				>
					Follow us
				</Text>
				<SocialMedia margin="0px 0px 0px 0px" justify-content="flex-start" linked-in="https://linkedin.com/company/jaouadpanorama-llc">
					<Override
						slot="link"
						color="--grey"
						padding="5x 5px 5px 5px"
						height="36px"
						display="flex"
						width="36px"
						align-items="center"
						justify-content="center"
						background="none"
						border-radius="50%"
						hover-color="--light"
						hover-background="transparent"
						margin="0 5px 0 5px"
					/>
					<Override
						slot="icon"
						color="--light"
						padding="7px 7px 7px 7px"
						border-width="0px"
						size="34px"
						border-radius="50px"
					/>
					<Override slot="link-facebook" margin="0px 5px 0px 0px">
						<div />
					</Override>
				</SocialMedia>
			</Box>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6143a05129a0a1001e6c835a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\n\n* {\n    scroll-behavior: smooth;\n    }"}
			</style>
		</RawHtml>
	</Theme>;
});